import { TRPCClientError, createTRPCProxyClient, httpBatchLink, } from '@trpc/client';
import superjson from 'superjson';
import { csrfHeaderName } from 'shared/headers';
export const cwaClient = createTRPCProxyClient({
    links: [
        httpBatchLink({
            url: import.meta.env.VITE_CWA_URL + 'trpc',
            headers: {
                [csrfHeaderName]: '',
            },
            async fetch(url, options) {
                // medTODO wrap with error handling. Also wrap ALL our fetches with error handling.
                return await fetch(url, {
                    ...options,
                    credentials: 'include',
                });
            },
        }),
    ],
    transformer: superjson,
});
export const augcClient = createTRPCProxyClient({
    links: [
        httpBatchLink({
            url: import.meta.env.VITE_AUGC_URL + 'trpc',
        }),
    ],
    transformer: superjson,
});
// https://trpc.io/docs/client/vanilla/infer-types#infer-trpcclienterror-types
export function isTrpcClientError(cause) {
    return cause instanceof TRPCClientError;
}
